import { Box } from '@orthly/ui-primitives';
import { GoogleOAuthProvider, GoogleLogin as GoogleLoginButton } from '@react-oauth/google';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';

export interface GoogleLoginProps {
    /* Unique client ID for Google SDK. Hides if not present. */
    clientId?: string;
    /* Called when login is complete */
    onSuccess: (args: { jwt: string }) => void;
    /* Called when login button is clicked, before popup */
    onClick?: () => void;
}

function notifyLoginError(reason: string) {
    enqueueSnackbar(`Login failed: ${reason}`, {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
}

export const GoogleLogin: React.FC<GoogleLoginProps> = props => {
    const { onClick, onSuccess, clientId } = props;

    const [searchParams] = useSearchParams();

    // Google sign in process will log to console in non-prod environments,
    // or in prod when using the URL parameter `&debug_google_login=1`.
    React.useEffect(() => {
        if (process.env.NODE_ENV !== 'production' || !!searchParams.get('debug_google_login')) {
            // @ts-ignore until @types/google.accounts is up to date
            google?.accounts?.id?.setLogLevel('debug');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // This enables use to pass a JWT in the URL for testing purposes.
    // This approach is being taken because googles CAPTCHA prevents us from automating google login
    // Retainer must be running in non-production mode to allow this and expects the jwt to be `psrUser`.
    const mockJwt = searchParams.get('googleLogInJwt');
    const isMockLogin = process.env.ALLOW_MOCK_LOGIN === 'true' && !!mockJwt;
    const clickHandler = React.useCallback(() => {
        if (isMockLogin) {
            onSuccess({ jwt: mockJwt });
        } else if (!!onClick) {
            onClick();
        }
    }, [isMockLogin, mockJwt, onSuccess, onClick]);

    if (!clientId) {
        return null;
    }

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <Box
                sx={{
                    paddingTop: 3,
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <GoogleLoginButton
                    containerProps={{
                        id: isMockLogin ? 'test_admin_portal_google_login_button' : '',
                    }}
                    use_fedcm_for_prompt={true}
                    size={'large'}
                    click_listener={clickHandler}
                    onSuccess={credentialResponse => {
                        // in the current implementation of @react-oauth/google, onSuccess is only called when the
                        // credential is nonempty, which the types don't reflect.
                        // @see https://github.com/MomenSherif/react-oauth/pull/383
                        onSuccess({ jwt: credentialResponse.credential as string });
                    }}
                    onError={() => {
                        notifyLoginError('There was an unexpected error logging in. Please try again later.');
                    }}
                />
            </Box>
        </GoogleOAuthProvider>
    );
};
